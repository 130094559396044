<app-header></app-header>
<div class="main-container">
  <div class="container-fluid inner-container">
    <div id="main" class="row">
      <div class="col-md-12">
        <h1 class="view-title">Hotel Settings</h1>
        
      </div>
    </div>
  </div>
</div>  
