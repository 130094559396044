<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <h1 class="view-title">{{ title }}</h1>
          <form [formGroup]="lockDetail">
            <div class="row">
              <div class="col-xs-6 col-md-2" style="margin-bottom: 10px">
                <div *ngIf="hotels.length > 1">
                  <label>Hotel</label>
                  <dropdown-menu
                    [menuList]="hotelList"
                    [selectedItem]="selectedHotel"
                    [fullWidth]="true"
                    (selectItem)="selectHotel($event)"
                  ></dropdown-menu>
                </div>
              </div>
              <div class="col-xs-6 col-md-3" style="margin-bottom: 10px">
                <ng-container *ngIf="!isCreateNewCode">
                  <label>Reservation</label>
                  <input class="form-control custom-input searchQuery" formControlName="reservation">
                </ng-container>
              </div>
              <div class="col-xs-12 col-md-7">
                <div class="row">
                  <div class="col-xs-12 col-md-6" style="margin-bottom: 10px">
                    <label>Select doors</label>
                    <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                      <div class="menu-list dropdown-toggle" [ngClass]="{error: !checkSelectedDoor()}" dropdownToggle aria-controls="optionalFilter">
                        <div class="menu-selected">
                          <span *ngIf="selectAllDoors" class="filter-item">
                            <span class="filter-label">All doors</span>
                          </span>
                          <ng-container *ngIf="!selectAllDoors">
                            <ng-container *ngFor="let filter of getMenuFilter(); let i = index">
                              <span *ngIf="filter.checked" class="filter-item">
                                <span class="filter-label">{{filter.label}}</span>
                                <span (click)="removeFilter(filter, $event)" class="glyphicon glyphicon-remove"></span>
                              </span>
                            </ng-container>
                            <span *ngIf="getAllFilter().length > 1" class="filter-item">
                              <span class="filter-label"> + {{getAllFilter().length - 1}}</span>
                            </span>
                          </ng-container>
                        </div>
                        <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                      </div>
                      <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu (click)="utils.preventClose($event)">
                        <div class="menu-tooltip-list" *ngIf="getAllFilter().length">
                          <div class="menu-title">Selected doors</div>
                          <ng-container *ngFor="let selectedDoor of getAllFilter()">
                            <div class="menu-item" [style]="{paddingLeft: selectedDoor.type === 'room' ? '20px' : '0px'}">
                              {{selectedDoor.label}}
                            </div>
                          </ng-container>
                        </div>
                        <div class="scroll-menu">
                          <div class="normal-checkbox" (click)="setAllDoors($event)">
                            <input type="checkbox" [checked]="selectAllDoors">
                            <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                            <div class="checkbox-label">All</div>
                          </div>
                          <ng-container *ngFor="let door of doorsList; let i = index">
                            <ng-container *ngIf="!['floor', 'room', 'elevator'].includes(door.type)">
                              <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                                <input type="checkbox" [checked]="door.checked">
                                <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                                <div class="checkbox-label">{{door.label}}</div>
                              </div>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngFor="let floor of allFloors">
                            <ng-container *ngFor="let door of doorsList; let i = index">
                              <!-- Elevator -->
                              <ng-container *ngIf="door.type === 'elevator' && door.floor === floor">
                                <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                                  <input type="checkbox" [checked]="door.checked">
                                  <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                                  <div class="checkbox-label">{{door.label}}</div>
                                </div>
                              </ng-container>
                              <!-- Rooms with entrances -->
                              <ng-container *ngIf="door.type === 'floor' && door.floor === floor && !missingEntranceFloors.includes(floor)">
                                <div class="accordion">
                                  <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{door.floor}}" [checked]="true">
                                  <div class="normal-checkbox">
                                    <span (click)="changeDoor(i,$event)">
                                      <input type="checkbox" [checked]="door.checked">
                                      <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                                      <div class="checkbox-label">{{door.label}}</div>
                                    </span>
                                    <label class="accordion-arrow" for="floor{{door.floor}}">
                                      <span class="glyphicon glyphicon-chevron-right"></span>
                                    </label>
                                  </div>
                                  <div class="accordion-content">
                                    <ng-container *ngFor="let roomDoor of doorsList; let roomIndex = index">
                                      <ng-container *ngIf="roomDoor.type === 'room' && roomDoor.floor === floor">
                                        <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                          <input type="checkbox" [checked]="roomDoor.checked">
                                          <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                                          <div class="checkbox-label">{{roomDoor.label}}</div>
                                        </div>
                                      </ng-container>
                                    </ng-container>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                            <!-- Rooms without entrances -->
                            <ng-container *ngIf="missingEntranceFloors.includes(floor)">
                              <div class="accordion">
                                <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{floor}}" [checked]="true">
                                <div class="normal-checkbox">
                                  <span>
                                    <input type="checkbox" disabled>
                                    <div class="checkbox-box disabled"><span class="glyphicon glyphicon-ok"></span></div>
                                    <div class="checkbox-label">
                                      Floor {{floor}}
                                      <div class="checkbox-desc">(No entrance)</div>
                                    </div>
                                  </span>
                                  <label class="accordion-arrow" style="top: -1px" for="floor{{floor}}">
                                    <span class="glyphicon glyphicon-chevron-right"></span>
                                  </label>
                                </div>
                                <div class="accordion-content">
                                  <ng-container *ngFor="let roomDoor of doorsList; let roomIndex = index">
                                    <ng-container *ngIf="roomDoor.type === 'room' && roomDoor.floor === floor">
                                      <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                        <input type="checkbox" [checked]="roomDoor.checked">
                                        <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                                        <div class="checkbox-label">{{roomDoor.label}}</div>
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-6" style="margin-bottom: 10px">
                    <label>Name</label>
                    <input class="form-control custom-input" [ngClass]="{error: doorCodeName.invalid}" formControlName="name">
                  </div>
                </div>
              </div>
              <div class="col-xs-6 col-md-2" style="margin-bottom: 10px">
                <label>Door code</label>
                <div class="datepicker-input">
                  <input type="text" placeholder="Input code" required class="form-control" [ngClass]="{error: doorCode.invalid}" formControlName="doorCode">
                  <button type="button" class="datepicker-btn" (click)="reRollDoorCode()"  [ngClass]="{disabled: !isCreateNewCode}">
                    <span class="glyphicon glyphicon-refresh"></span>
                  </button>
                </div>
              </div>
              <div class="col-xs-6 col-md-3" style="margin-bottom: 10px">
                <ng-container *ngIf="!isCreateNewCode">
                  <label class="empty-label-for-btn"></label>
                  <button class="btn btn-green input-height full-width" (click)="openEventLog()">Event log</button>
                </ng-container>
              </div>
              <div class="col-xs-12 col-md-7">
                <div class="row" [ngClass]="{disabled:deactivate}">
                  <div class="col-sm-6" style="margin-bottom: 10px">
                    <label>Start date & time</label>
                    <div class="row no-gutters">
                      <div class="col-xs-7">
                        <div class="datepicker-input">
                          <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                            [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                            (bsValueChange)="dateChange('startDate')"
                            [dateCustomClasses]="datepickerCustom"
                            [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                          <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                            <span class="glyphicon glyphicon-calendar"></span>
                          </button>
                        </div>
                      </div>
                      <div class="col-xs-5">
                        <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
                          <div class="datepicker-input">
                            <input type="text" placeholder="Start time" class="form-control" value="{{startDate | date:'HH:mm'}}"
                              disabled>
                            <button type="button" class="datepicker-btn" dropdownToggle aria-controls="startTimePicker">
                              <span class="glyphicon glyphicon-time"></span>
                            </button>
                          </div>
                          <div class="dropdown-menu menu-content start-time-cal" id="startTimePicker" *dropdownMenu (click)="preventClose($event)">
                            <timepicker [(ngModel)]="startDate" [showMeridian]="false" [ngModelOptions]="{standalone: true}" [hourStep]="1" [minuteStep]="1"></timepicker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6" style="margin-bottom: 10px">
                    <label>End date & time</label>
                    <div class="row no-gutters">
                      <div class="col-xs-7">
                        <div class="datepicker-input">
                          <input type="text" placeholder="Select end date" class="form-control" appEditDate [(bsValue)]="endDate"
                            bsDatepicker #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                            (bsValueChange)="dateChange('endDate')"
                            [dateCustomClasses]="datepickerCustom"
                            [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-end-cal', dateInputFormat: 'DD.MM.YYYY', minDate: startDate, customTodayClass:'datepicker-today' }">
                          <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                            <span class="glyphicon glyphicon-calendar"></span>
                          </button>
                        </div>
                      </div>
                      <div class="col-xs-5">
                        <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
                          <div class="datepicker-input">
                            <input type="text" placeholder="End time" class="form-control" value="{{endDate | date:'HH:mm'}}" disabled>
                            <button type="button" class="datepicker-btn" dropdownToggle aria-controls="endTimePicker">
                              <span class="glyphicon glyphicon-time"></span>
                            </button>
                          </div>
                          <div class="dropdown-menu menu-content start-time-cal" id="endTimePicker" *dropdownMenu (click)="preventClose($event)">
                            <timepicker [(ngModel)]="endDate" [showMeridian]="false" [ngModelOptions]="{standalone: true}" [hourStep]="1" [minuteStep]="1"></timepicker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="normal-checkbox" style="margin-top: 10px" (click)="setDeactivate($event)" *ngIf="!isCreateNewCode">
                  <input type="checkbox" [checked]="deactivate">
                  <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                  <div class="checkbox-label">De-Activate</div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="right-btns">
                  <button class="btn btn-green input-height" (click)="saveDoorCode()" [disabled]="lockDetail.invalid || !isFormValid || !checkSelectedDoor()"><span class="glyphicon glyphicon-asterisk" style="margin-right: 5px"></span> Save</button>
                  <button class="btn btn-green input-height" (click)="backToLock()"><span class="glyphicon glyphicon-ban-circle" style="margin-right: 5px"></span> Cancel</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>