import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HotelType, HotelWebConfig } from 'src/app/@types/app';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { BeonxHotelNotifExportType, InternalToolsService } from 'src/app/services/internal-tools.service';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../../common/dropdown-menu/dropdown-menu.component';
import { ReportsService } from 'src/app/services/reports.service';
import { AllotmentsService } from 'src/app/services/allotments.service';

@Component({
  selector: 'app-beonx-hotel-notif-export',
  templateUrl: './beonx-hotel-notif-export.component.html',
  styleUrls: ['../internal-tools-mui.component.sass']
})
export class BeonxHotelNotifExportComponent implements OnInit {

  @Input() hotels:HotelType[] = [];

  constructor(
    private storeService: StoreService,
    public utils: UTILS,
    private internalToolsService: InternalToolsService,
    private allotmentsService: AllotmentsService,
    private toastr: ToastrService,
  ) { }

  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  hotelList: MenuType[] = [];
  selectedHotel?:MenuType;

  loadingAllotment:boolean = false;
  allotmentList:MenuType[] = [];
  selectedAllotment?:MenuType;

  fileTypeList:MenuType[] = [
    {
      value: "OTA_HotelResNotifRQ",
      label: "OTA_HotelResNotifRQ",
      disabled: false
    },
    {
      value: "OTA_HotelInvBlockNotifRQ",
      label: "OTA_HotelInvBlockNotifRQ",
      disabled: false
    },
    {
      value: "OTA_HotelInvCountNotifRQ",
      label: "OTA_HotelInvCountNotifRQ",
      disabled: false
    }
  ];
  selectedFileType:MenuType = this.fileTypeList[0];

  retrieveForm = new FormGroup({
    reservationCode: new FormControl('', [this.validateReservationCode.bind(this)]),
  });
  get reservationCode() {
    return this.retrieveForm.get("reservationCode")!
  }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    if(this.hotels.length) {
      let tmpHotelList:MenuType[] = [];
      this.hotels.forEach((hotel:any) => {
        tmpHotelList.push({
          label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
          value: hotel.hotelId.toString(),
          disabled: false
        })
      })
      this.hotelList = tmpHotelList;
      this.selectHotel(tmpHotelList[0])
      this.getAllotmentList(tmpHotelList[0])
    }
  }

  getAllotmentList(hotel:MenuType) {
    this.loadingAllotment = true;
    const request:any = {
      hotelIds: hotel.value
    }
    this.allotmentsService.searchAllotments(request).subscribe(data => {
      let tmpAllotmentList:MenuType[] = [
        {
          label: "--",
          value: "none",
          disabled: false
        }
      ]
      data.forEach((allotment:any) => {
        tmpAllotmentList.push({
          label: `(${allotment.allotmentType}) ${allotment.name}`,
          value: allotment.id.toString(),
          disabled: false
        })
      })
      this.selectAllotment(tmpAllotmentList[0]);
      this.allotmentList = tmpAllotmentList;
      this.loadingAllotment = false;
    }, error => {
      this.loadingAllotment = false;
      this.toastr.error("An error occurred while loading allotments!", "Error");
    })
  }
  selectAllotment(item:MenuType) {
    this.selectedAllotment = item;
  }

  displayAPIerror(err:any) {
    let errorMessage;
    if(err.error === "NOT_FOUND") {
      errorMessage = "Cannot find reservation or allotment";
    } else {
      try {
        let message = JSON.parse(err.message);
        switch (message.code) {
          case 77000:
            errorMessage = "OTA_HotelInvBlockNotifRQ only supports group reservations";
            break;
        
          default:
            errorMessage = `Unknown error occurred (code: ${message.code})!`
            break;
        }
      } catch(e) {
        errorMessage = err.message;
      }
    }
    if(!errorMessage) errorMessage = "Unknown error occurred!";
    this.toastr.error(errorMessage, 'Error!');
  }

  selectHotel(item:MenuType) {
    this.selectedHotel = item;
    this.getAllotmentList(item);
  }

  selectFileType(item:MenuType) {
    this.selectedFileType = item;
    if(this.retrieveForm) {
      this.retrieveForm.controls["reservationCode"].markAsTouched();
      this.retrieveForm.controls["reservationCode"].updateValueAndValidity();
    }
  }

  validateReservationCode(control:AbstractControl): {[key:string]:boolean} | null {
    let invalidObject = {'invalidMobile':true};
    if (this.selectedFileType.value !== "OTA_HotelResNotifRQ" || (this.selectedFileType.value === "OTA_HotelResNotifRQ" && this.retrieveForm && this.retrieveForm.controls.reservationCode.value !== "")) {
      return null;
    }
    return invalidObject;
  }

  exportHotelNotif() {
    this.retrieveForm.controls["reservationCode"].markAsTouched();
    this.retrieveForm.controls["reservationCode"].updateValueAndValidity();
    if(this.retrieveForm.valid && this.selectedFileType) {

      let formValid = true;
      if(this.selectedFileType.value === "OTA_HotelInvBlockNotifRQ" && this.selectedAllotment && this.selectedAllotment.value === "none" && !this.reservationCode.value) {
        formValid = false;
        this.toastr.error("Please provide either Allotment or Reservation code for OTA_HotelInvBlockNotifRQ file type!", "Error!");
      } else {
        formValid = true;
      }

      if(formValid) {
        const typeNeedReservationCode = ['OTA_HotelInvBlockNotifRQ', 'OTA_HotelResNotifRQ'];
        const typeNeedHotel = ['OTA_HotelInvBlockNotifRQ', 'OTA_HotelInvCountNotifRQ'];
        const typeNeedAllotment = ['OTA_HotelInvBlockNotifRQ'];
        const findHotel = this.hotels.find((hotel:any) => hotel.hotelId === Number(this.selectedHotel?.value))

        let request:BeonxHotelNotifExportType = {} as BeonxHotelNotifExportType;
        request.type = this.selectedFileType.value;
        if(findHotel && typeNeedHotel.includes(this.selectedFileType.value)) {
          request.hotelLabel = findHotel.label;
        }
        if(typeNeedReservationCode.includes(this.selectedFileType.value)) {
          if(this.reservationCode.value) {
            request.reservationCode = this.reservationCode.value;
          }
        }
        if(this.selectedAllotment && this.selectedAllotment.value !== "none" && typeNeedAllotment.includes(this.selectedFileType.value)) {
          request.allotmentId = this.selectedAllotment.value;
        }

        let url = `/api/management/secure/beonx/xml-data`;
        let fileName = `${this.selectedFileType.value}`;
        if(typeNeedReservationCode.includes(this.selectedFileType.value) && this.reservationCode.value) {
          fileName += `-${this.reservationCode.value}`;
        }
        if(typeNeedHotel.includes(this.selectedFileType.value) && findHotel) {
          if(this.selectedFileType.value === "OTA_HotelInvBlockNotifRQ" && this.selectedAllotment && this.selectedAllotment.value !== "none" && !this.reservationCode.value) {
            fileName += `-${findHotel.label}`;
          }
        }
        if(typeNeedAllotment.includes(this.selectedFileType.value) && this.selectedAllotment && this.selectedAllotment.value !== "none" && !this.reservationCode.value) {
          fileName += `-${this.selectedAllotment.label}`;
        }
        this.internalToolsService.downloadXmlPost(url, request).subscribe(blob => {
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = `${fileName}.xml`;
          a.click();
          URL.revokeObjectURL(objectUrl);
        }, async err => {
          const message = JSON.parse(await err.error.text())
          this.displayAPIerror(message)
        });
      }
    }
  }

}
