<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container price-block">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <h1 class="view-title">{{ content.pricing.title }}</h1>
        </div>
        <form [formGroup]="pricingForm">
          <div class="row row-bottom no-gutters">
              <div class="col-md-2 col-xs-6" *ngIf="hotels.length > 1">
                <label for="select-report">Search by hotel</label>
                <dropdown-menu
                  [menuList]="hotelList"
                  [selectedItem]="selectedHotel"
                  [fullWidth]="true"
                  (selectItem)="selectHotel($event)"
                ></dropdown-menu>
              </div>
              <div class="col-md-2 col-xs-6">
                <label for="select-report">Product type</label>
                <dropdown-menu
                  [menuList]="roomTypeList"
                  [selectedItem]="selectedRoomType"
                  [fullWidth]="true"
                  (selectItem)="selectRoomType($event)"
                ></dropdown-menu>
              </div>
              <div class="col-md-2 col-xs-6">
                <label>Start date</label>
                <div class="datepicker-input">
                  <input type="text" placeholder="Start date" class="form-control" bsDatepicker appEditDate
                    [(bsValue)]="startDate" #startDateInput="bsDatepicker"
                    (bsValueChange)="dateChange('startDate')"
                    [dateCustomClasses]="datepickerCustom"
                    [bsConfig]="{isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                  <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                    <span class="glyphicon glyphicon-calendar"></span>
                  </button>
                </div>
            </div>
            <div class="col-md-2 col-xs-6">
                <label>End date</label>
                <div class="datepicker-input">
                  <input type="text" placeholder="End date" class="form-control" bsDatepicker appEditDate
                    [(bsValue)]="endDate" #endDateInput="bsDatepicker"
                    (bsValueChange)="dateChange('endDate')"
                    [dateCustomClasses]="datepickerCustom"
                    [bsConfig]="{isAnimated: true, containerClass:'theme-red end-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                  <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                    <span class="glyphicon glyphicon-calendar"></span>
                  </button>
                </div>
            </div>
            <div class="col-md-4 col-xs-12">
              <label>Only on specific weekdays</label>
              <div class="weekday-selector">
                <div class="custom-radio" *ngFor="let weekday of selectedWeekdays">
                  <input type="checkbox" formControlName="selectedWeekday"
                    (ngModelChange)="weekdayChange($event,weekday.label)">
                  <div class="radio-box">{{weekday.label}}</div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <ng-container *ngIf="ruleList && selectedRoomType && selectedRoomType.value !== 'SAMEDATE_ECI_FLEX' && hasPricingRule && hotelConfig.MUIfeature.usePricingRules">
          <div class="head-title">Pricing rules</div>
          <ng-container *ngFor="let rule of ruleList">
            <div class="rule-container" *ngIf="rule.supported">
              <div class="first-text">{{rule.ruleName}} = {{rule.ruleBaseValueName}}</div>
              <div class="second-text" *ngIf="!rule.editMode">{{rule.originalRule.operator.label}} {{rule.originalRule.value}} {{rule.originalRule.valueType.label}}</div>
              <div class="second-text-edit" *ngIf="rule.editMode">
                <div class="operator">
                  <dropdown-menu
                    [menuList]="ruleOperatorList"
                    [selectedItem]="rule.rule.operator"
                    [fullWidth]="true"
                    (selectItem)="selectRuleOperator($event, rule.ruleValue)"
                  ></dropdown-menu>
                </div>
                <div class="value-field">
                  <input class="form-control" [ngModel]="rule.rule.value" (ngModelChange)="changeRuleValue($event, rule.ruleValue)">
                </div>
                <div class="value-type">
                  <dropdown-menu
                    [menuList]="ruleValueTypeList"
                    [selectedItem]="rule.rule.valueType"
                    [fullWidth]="true"
                    (selectItem)="selectRuleValueType($event, rule.ruleValue)"
                  ></dropdown-menu>
                </div>
              </div>
              <div class="action-button">
                <button *ngIf="!rule.editMode" class="edit-button" (click)="showRuleEdit(rule.ruleValue)">
                  <span class="glyphicon glyphicon-edit"></span> Edit
                </button>
                <ng-container *ngIf="rule.editMode">
                  <button class="btn btn-green" (click)="saveRuleEdit(rule.ruleValue)">
                    <span class="glyphicon glyphicon-ok"></span>
                  </button>
                  <button class="btn btn-outline-green" (click)="discardRuleEdit(rule.ruleValue)">
                    <span class="glyphicon glyphicon-remove"></span>
                  </button>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
        
      </div>
    </div>
  </div>

  <div class="center-item" *ngIf="isLoading">
    <ng-lottie
      [options]="options"
      width="200px"
      height="200px"
    ></ng-lottie>
  </div>

  <ng-template #rateParamInfo>
    <div class="popover-info">
      min and max values within which the price will move dynamically.
    </div>
  </ng-template>

  <ng-template #occupancyParamInfo>
    <div class="popover-info">
      min and max occupancy levels for the selected date.
      <br>1. When beyond occupancy paramaters, nPrice uses the rate parameter min/max.
      <br>2. The value between min/max occupancy will be directly used as the ratio between the min/max of the price. For example: if min occupancy <b>30%</b> and max occupancy is <b>100%</b>, and predicted occupancy is <b>65%</b>, then ratio of <b>0.5</b> (65% falls exactly in the middle of 30% and 100%. So when considering 30% as min and 100% as max, the resulting normalized factor is 0.5). If rate parameters is set to <b>50 {{utils.getCurrency(currency)}}</b> min and <b>100 {{utils.getCurrency(currency)}}</b> max, then the resulting price will be <b>0.5 * (200-50) + 50 = 125 {{utils.getCurrency(currency)}}</b>.
    </div>
  </ng-template>

  <ng-template #manualPriceInfo>
    <div class="popover-info">
      Prices in Manual price columns will ignore Pricing rules and Dynamic price when the checkbox is checked.
    </div>
  </ng-template>

  <ng-template #dynamicPriceInfo>
    <div class="popover-info">
      This is the price generated by algorithms which predict optimal prices on each dates. Rate parameters and Occupancy parameters can be used to adjust the Dynamic price rules.
    </div>
  </ng-template>

  <ng-template #nonFullPricingOTAPriceInfo>
    <div class="popover-info">
      When the checkbox is checked, the OTA service of the column will use the inputed price instead of using Nelson price.
    </div>
  </ng-template>

  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <div [ngClass]="{hide: isLoading || roomTypeList.length === 0}">
          <div class="text-right" style="margin-bottom: 20px;">
            <button type="button" class="btn btn-outline-green btn-big left-margin" (click)="fetch()"><span
              class="glyphicon glyphicon-refresh"></span> {{ content.pricing.resetBtn }}</button>
            <button type="button" class="btn btn-green btn-big left-margin" (click)="save()" [disabled]="!isSaveValid || disabledBtnWhileCallingAPI"><span class="glyphicon glyphicon-save"></span> {{ content.pricing.saveBtn }}</button>
          </div>
          <!-- Full pricing table -->
          <table id="normalProduct" class="custom-table" *ngIf="selectedRoomType && selectedRoomType.value !== 'SAMEDATE_ECI_FLEX' && hotelConfig.MUIfeature.fullPricingPage">
            <thead>
              <tr>
                <th>Date</th>
                <th colspan="2">
                  <div class="title-with-info-container">
                    <div class="twi-title">Rate parameters ({{utils.getCurrency(currency)}})</div>
                    <div class="twi-info">
                      <span class="glyphicon glyphicon-info-sign" triggers="mouseenter:mouseleave" [popover]="rateParamInfo" placement="right" [adaptivePosition]="false"></span>
                    </div>
                  </div>
                </th>
                <th colspan="2">
                  <div class="title-with-info-container">
                    <div class="twi-title">Occupancy parameters (%)</div>
                    <div class="twi-info">
                      <span class="glyphicon glyphicon-info-sign" triggers="mouseenter:mouseleave" [popover]="occupancyParamInfo" placement="right" [adaptivePosition]="false"></span>
                    </div>
                  </div>
                </th>
                <th rowspan="2">
                  <div class="title-with-info-container">
                    <div class="twi-title">Dynamic price</div>
                    <div class="twi-info">
                      <span class="glyphicon glyphicon-info-sign" triggers="mouseenter:mouseleave" [popover]="dynamicPriceInfo" placement="right" [adaptivePosition]="false"></span>
                    </div>
                  </div>
                </th>
                <th colspan="3">
                  <div class="title-with-info-container center-aligned">
                    <div class="twi-title">Manual price</div>
                    <div class="twi-info">
                      <span class="glyphicon glyphicon-info-sign" triggers="mouseenter:mouseleave" [popover]="manualPriceInfo" placement="right" [adaptivePosition]="false"></span>
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th></th>
                <th>min</th>
                <th>max</th>
                <th>min</th>
                <th>max</th>
                <th>Nelson</th>
                <th *ngIf="checkSupportedOTA('BOOKINGCOM')">Booking.com</th>
                <th *ngIf="checkSupportedOTA('EXPEDIA')">Expedia</th>
              </tr>
              <tr>
                <th></th>
                <th class="pricing-input"><input type="text" name="minRate" (keyup)="multipleInput('normalProduct','minRate',$event)"></th>
                <th class="pricing-input"><input type="text" name="maxRate" (keyup)="multipleInput('normalProduct','maxRate',$event)"></th>
                <th class="pricing-input"><input type="text" name="minOccupancy" (keyup)="multipleInput('normalProduct','minOccupancy',$event)"></th>
                <th class="pricing-input"><input type="text" name="maxOccupancy" (keyup)="multipleInput('normalProduct','maxOccupancy',$event)"></th>
                <th></th>
                <th class="nelson"><input class="nelsonInput" type="text" name="nelsonPrice" (keyup)="multipleInput('normalProduct','nelsonPrice',$event)"></th>
                <th class="nelson"><input class="nelsonInput" type="text" name="bookingComPrice" (keyup)="multipleInput('normalProduct','bookingComPrice',$event)"></th>
                <th class="nelson"><input class="nelsonInput" type="text" name="expediaPrice" (keyup)="multipleInput('normalProduct','expediaPrice',$event)"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let rate of priceRates; let i = index">
                <tr [attr.data-row]="i" [attr.data-weekday]="getWeekday(rate.date)">
                  <td>{{convertStringDate(rate.date)}}</td>
                  <td class="pricing-input">
                    <input type="text" name="minRate" value="{{rate.minRate}}" (input)="validateTableInput('normalProduct',i,'minRate',$event)">
                    <div class="currency">{{utils.getCurrency(currency)}}</div>
                  </td>
                  <td class="pricing-input">
                    <input type="text" name="maxRate" value="{{rate.maxRate}}" (input)="validateTableInput('normalProduct',i,'maxRate',$event)">
                    <div class="currency">{{utils.getCurrency(currency)}}</div>
                  </td>
                  <td class="pricing-input">
                    <input type="text" name="minOccupancy" value="{{rate.minOccupancy}}" (input)="validateTableInput('normalProduct',i,'minOccupancy',$event)">
                    <div class="currency">%</div>
                  </td>
                  <td class="pricing-input">
                    <input type="text" name="maxOccupancy" value="{{rate.maxOccupancy}}" (input)="validateTableInput('normalProduct',i,'maxOccupancy',$event)">
                    <div class="currency">%</div>
                  </td>
                  <td class="exRate" [ngClass]="{active: !checkUseNelsonRate(rate.prices)}">
                    <div class="price">{{rate.externalRate}}</div>
                    <div class="currency">{{utils.getCurrency(currency)}}</div>
                  </td>
                  <ng-container *ngFor="let priceRate of rate.prices; let priceIndex = index">
                    <td class="nelson" [ngClass]="{active: priceRate.useCustomPrice && priceRate.bookingChannel === 'NELSON'}">
                      <div class="wrapper-input">
                        <div class="input-field">
                          <input class="nelsonInput" type="text" [attr.name]="manualPriceName[priceRate.bookingChannel]" value="{{priceRate.customPrice}}" (input)="validateTableInput('normalProduct',i,manualPriceName[priceRate.bookingChannel],$event)">
                        </div>
                        <div class="currency">{{utils.getCurrency(currency)}}</div>
                        <div class="input-checkbox">
                          <div class="normal-checkbox no-label">
                            <input type="checkbox" (change)="changeUseManual($event,i,priceIndex)" [checked]="priceRate.useCustomPrice">
                            <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <!-- Non full pricing table -->
          <table id="normalProduct" class="custom-table compact" *ngIf="selectedRoomType && selectedRoomType.value !== 'SAMEDATE_ECI_FLEX' && !hotelConfig.MUIfeature.fullPricingPage">
            <thead>
              <tr>
                <th>Date</th>
                <th>Nelson price</th>
                <th *ngIf="checkSupportedOTA('BOOKINGCOM')">
                  <div class="title-with-info-container">
                    <div class="twi-title">Booking.com price</div>
                    <div class="twi-info">
                      <span class="glyphicon glyphicon-info-sign" triggers="mouseenter:mouseleave" [popover]="nonFullPricingOTAPriceInfo" placement="right" [adaptivePosition]="false"></span>
                    </div>
                  </div>
                </th>
                <th *ngIf="checkSupportedOTA('EXPEDIA')">
                  <div class="title-with-info-container">
                    <div class="twi-title">Expedia price</div>
                    <div class="twi-info">
                      <span class="glyphicon glyphicon-info-sign" triggers="mouseenter:mouseleave" [popover]="nonFullPricingOTAPriceInfo" placement="right" [adaptivePosition]="false"></span>
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th></th>
                <th class="nelson"><input class="nelsonInput" type="text" name="nelsonPrice" (keyup)="multipleInput('normalProduct','nelsonPrice',$event)"></th>
                <th class="nelson"><input class="nelsonInput" type="text" name="bookingComPrice" (keyup)="multipleInput('normalProduct','bookingComPrice',$event)"></th>
                <th class="nelson"><input class="nelsonInput" type="text" name="expediaPrice" (keyup)="multipleInput('normalProduct','expediaPrice',$event)"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let rate of priceRates; let i = index">
                <tr [attr.data-row]="i" [attr.data-weekday]="getWeekday(rate.date)">
                  <td>{{convertStringDate(rate.date)}}</td>
                  <ng-container *ngFor="let priceRate of rate.prices; let priceIndex = index">
                    <td class="nelson">
                      <div class="wrapper-input">
                        <div class="input-field">
                          <input class="nelsonInput" type="text" [attr.name]="manualPriceName[priceRate.bookingChannel]" value="{{priceRate.customPrice}}" (input)="validateTableInput('normalProduct',i,manualPriceName[priceRate.bookingChannel],$event)">
                        </div>
                        <div class="currency">{{utils.getCurrency(currency)}}</div>
                        <div class="input-checkbox" *ngIf="priceRate.bookingChannel !== 'NELSON'">
                          <div class="normal-checkbox no-label">
                            <input type="checkbox" (change)="changeUseManual($event,i,priceIndex)" [checked]="priceRate.useCustomPrice">
                            <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <div class="col-md-4" [ngClass]="{hide: hotelConfig.MUIfeature.fullPricingPage}" *ngIf="selectedRoomType && selectedRoomType.value !== 'SAMEDATE_ECI_FLEX'"></div>
      
          <table id="secifProduct" class="custom-table secif-table" *ngIf="selectedRoomType && selectedRoomType.value === 'SAMEDATE_ECI_FLEX'">
            <thead>
              <tr>
                <th>Date</th>
                <th *ngFor="let time of timeSECIF">{{time.time}}</th>
                <th>All</th>
              </tr>
              <tr>
                <th></th>
                <th *ngFor="let time of timeSECIF">
                  <input type="text" name="{{time.name}}" (keyup)="multipleInput('secifProduct',time.name,$event)">
                </th>
                <th>
                  <input type="text" name="allTable" (keyup)="allInput('allTable',0,$event)">
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let rate of secifPrice; let i = index">
                <tr [attr.data-row]="i" [attr.data-weekday]="getWeekday(rate.date)">
                  <td>{{convertStringDate(rate.date)}}</td>
                  <td class="secifRow" *ngFor="let time of timeSECIF">
                    <input type="text" name="{{time.name}}" value="{{getSecifTimePrice(i,time.name)}}" (input)="validateTableInput('secifProduct',i,time.name,$event)">
                  </td>
                  <td><input type="text" name="allRow" (keyup)="allInput('allRow',i,$event)"></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
      
      </div>
      </div>
    </div>
  </div>
  <!-- <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <div class="row" [ngClass]="{hide: isLoading}">
          <div class="col-md-8"></div>
          <div class="col-md-4 text-right"
            ng-show="contentVisible && currentRoomType === 'SAMEDATE_ECI_FLEX'">
            <button type="button" class="btn btn-outline-green btn-big left-margin" (click)="fetch()"><span
              class="glyphicon glyphicon-refresh"></span> {{ content.pricing.resetBtn }}</button>
            <button type="button" class="btn btn-green btn-big left-margin" (click)="save()" [disabled]="!isSaveValid || disabledBtnWhileCallingAPI"><span class="glyphicon glyphicon-save"></span> {{ content.pricing.saveBtn }}</button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
<!-- alert modal -->
<ng-template #alertModal>
  <div class="modal-body text-center">
    <div class="error-icon"><span class="glyphicon glyphicon-question-sign"></span></div>
    <div class="error-desc">Please enter the value of Nelson Price</div>

    <button type="button" class="btn btn-green" (click)="modalRef.hide()">Ok</button>
     </div>
</ng-template>
<app-footer></app-footer>